import React, {useEffect, useState} from "react";
import {graphql, Link, navigate} from 'gatsby';
import { Button } from 'antd';
import queryString from 'query-string'
import SecureLS from "secure-ls";
import Api from "../../components/common/Api";
import '../../styles/index.css';
import '../../styles/tailwind.css';
import Auth from "../../components/layouts/Auth";
import SEO from "../../components/seo";

export default function EmailAuth() {
  const [controlSuccess, setControlSuccess] = useState()
  
  useEffect(() => {
    if(typeof localStorage !== 'undefined'){
      const ls = new SecureLS({encodingType: 'aes'});
      ls.removeAll();
    }
    const urlParams = queryString.parse(window.location.search)
    const code = urlParams.code
    if(code === undefined){
      navigate("/")
    }else {
      Api.checkActivationCode(
          {
            code: code
          }
      )
      .then((response) => {
        console.log(response.data)
        if(response.data.code === 2){
          setControlSuccess(false);
          window.location.href = '/'
        }else if(response.data.code === 1){
          setControlSuccess(true);
          setTimeout(() => {
            navigate("/")
          }, 3000);
        }
      })
      .catch((err) => console.log(err.response.request));
    }
  }, [])
  
  return (
      <>
        <SEO
            title="Aktivasyon Kodu"
            description="Bu sayfadan Fransızca Öğreniyorum egzersiz kütüphanesi üyeliğinizin aktivasyonunu yapabilirsiniz."
        />
        <Auth>
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
                  <div className="rounded-t mb-0 px-6 py-6">
                    <div className="text-center mb-3">
                      {controlSuccess === true ?
                      <>
                        <h6 className="text-gray-600 text-sm font-bold">
                          Başarıyla üyeliğinizi aktifleştirdiniz. Tekrardan giriş yaptıktan sonra, kütüphaneye gidip egzersiz yapabilirsiniz.
                        </h6>
                        <Link to="/egzersizler">
                          <Button type="primary">Egzersiz Kütüphanesine Git!</Button>
                        </Link>
                      </> :
                      <>
                        <h6 className="text-gray-600 text-sm font-bold">
                          Üyelik aktivasyonu başarısız! Lütfen aşağıdaki buton ile bizimle iletişime geçiniz!
                        </h6>
                        <a href="mailto:iletisim@paradyo.com"><Button type="danger">İletişime Geç!</Button></a>
                      </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Auth>
      </>
  );
}
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`